










import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import OperationsComponent from '@/partials/components/Events/Subscriptions/Operations.vue'

@Component({
  components: {
    OperationsComponent
  }
})
export default class EventSubscriptionOperations extends Vue {
  done = false
  subscription = {
    nameFull: '',
    nameCred: '',
    sex: '',
    email: '',
    cpf: '',
    rg: '',
    crm: '',
    teot: '',
    address: '',
    cep: '',
    uf: '',
    phone: '',
    mobile: '',
    country: '',
    institution: '',
    notes: '',
    subscriptionType: {
      typePrice: ''
    },
    paid: 0
  }

  created () {
    this.done = false
    const { eventId, subscriptionId } = this.$route.params
    axios.get(`/event/${eventId}/subscription/${subscriptionId}`)
      .then(response => {
        this.subscription = camelCaseKeys(response.data.data, { deep: true })
      }).finally(() => { this.done = true })
  }
}
